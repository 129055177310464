import React from 'react'
import {
  Content,
  DescriptionWrapper,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './WhenYouNeedLichniiPsiholog.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const WhenYouNeedLichniiPsiholog = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <ImageWrapper>
            <HeroImage alt="Когда нужна помощь личного психолога" />
          </ImageWrapper>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>Когда нужна помощь личного психолога</Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Личный психолог может стать вашим союзником в различных
                ситуациях. Эмоциональное расстройство, стресс, тревожность,
                депрессия, сложности в отношениях, личностные кризисы — вот лишь
                несколько сигналов о том, что пришло время обратиться за
                помощью. Профессиональный психолог поможет вам понять и решить
                эти вызовы.{' '}
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
