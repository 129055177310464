import React from 'react'
import {
  Br,
  ButtonsWrapper,
  Content,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './FindYourLichniiSpecialist.styles'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { DescriptionWrapper, TitleWrapper } from '../Common.styles'
import { Event as GAEvent } from '../../../components/GA'

export const FindYourLichniiSpecialist = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Найдите личного эксперта
                <Br /> под ваш запрос прямо сейчас!
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Путешествие к лучшей версии себя начинается с понимания и
                поддержки. Наши личные психологи готовы помочь вам на этом пути.
                Они станут вашими менторами, гидами и союзниками в достижении
                эмоционального благополучия. Найдите своего личного эксперта
                прямо сейчас и начните свое путешествие к психологическому
                равновесию{' '}
              </Text.Large>
            </DescriptionWrapper>
            <ButtonsWrapper>
              <Button.NewPrimary
                href="/wizard/"
                onClick={GAEvent.openSemeinyiPageFirstScreen}
                size="large"
                type="link"
              >
                Начать терапию
              </Button.NewPrimary>
            </ButtonsWrapper>
          </InfoContainer>
          <ImageWrapper>
            <HeroImage alt="Найдите личного эксперта под ваш запрос прямо сейчас" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
