import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Button, Text, Title } from '../../../youtalk-storybook/src/ui'
import {
  DescriptionWrapper,
  InfoContainer,
  TitleWrapper
} from './Common.styles'
import { Event as GAEvent } from '../../components/GA'
import { Grid } from '../../atoms/Grid'
import { GridLegacyCards } from '../../molecules/GridLegacyCards'
import { PsychologistMiniCardAnexity } from '../PsychologistMiniCard'
import { color } from '../../styles/vars/colors'
import { isBrowser } from '../../atoms/root'
import { randomChoice } from '../../atoms/randomChoice'
import { selectDeviceSize } from '../../state/reducers/deviceSlice'
import { size } from '../../constants'
import { useSelector } from 'react-redux'

const ButtonToForm = styled(({ className }) => (
  <div className={className}>
    <Button.NewPrimary
      transparent
      href="/wizard/"
      onClick={GAEvent.openSemeinyiPageFirstScreen}
      size="large"
      type="link"
    >
      Подобрать психолога
    </Button.NewPrimary>
  </div>
))`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;

  @media (max-width: ${size.md}) {
    flex-direction: column;
    gap: 16px;

    & ${Button.NewPrimary}, ${Button.NewOutline} {
      width: 100%;
    }
  }
`

const Br = styled.br`
  @media (max-width: ${size.sm}) {
    display: none;
  }
`

export const PsychologistsWithLichnii = styled(({ className, data }) => {
  const windowWidth = useSelector(selectDeviceSize)
  const psychologists = useMemo(
    () => randomChoice(data.psychologists.edges.map(({ node }) => node)),
    [data.psychologist]
  )

  const countByWindowWidth = useMemo(
    () => (windowWidth < 1024 ? 2 : 3),
    [windowWidth]
  )

  const psychologistSliced = useMemo(
    () => (isBrowser ? psychologists.slice(0, countByWindowWidth) : []),
    [countByWindowWidth]
  )

  return (
    <section className={className}>
      <Grid>
        <InfoContainer>
          <TitleWrapper>
            <Title.H2>
              Как проходит онлайн-сессия
              <Br /> с личным психологом в YouTalk
            </Title.H2>
          </TitleWrapper>
          <DescriptionWrapper>
            <Text.Large semiBold>
              Наши онлайн-сессии с личным психологом в YouTalk – это удобный и
              конфиденциальный способ получить поддержку. Во время сессии вы
              сможете обсудить свои эмоции, переживания, проблемы и цели. Ваш
              психолог предоставит вам ценные инструменты для саморазвития,
              поможет рассмотреть ситуации с новой перспективой и разработает
              стратегии решения.
            </Text.Large>
          </DescriptionWrapper>
        </InfoContainer>
        <GridLegacyCards>
          {psychologistSliced.map((psychologist) => (
            <PsychologistMiniCardAnexity
              key={psychologist.id}
              psychologist={psychologist}
            />
          ))}
        </GridLegacyCards>
        <ButtonToForm />
      </Grid>
    </section>
  )
})`
  background: #dfebf9;
  padding-top: 72px;
  padding-bottom: 72px;

  @media (max-width: ${size.md}) {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  & ${PsychologistMiniCardAnexity} {
    background: ${color.background};
    height: 468px;
  }

  & ${InfoContainer} {
    display: flex;
    gap: 24px;
    align-items: flex-start;
    margin-bottom: 36px;

    @media (max-width: ${size.lg}) {
      flex-direction: column;
      gap: 16px;
    }

    @media (max-width: ${size.md}) {
      margin-bottom: 24px;
    }

    @media (max-width: ${size.sm}) {
      margin-bottom: 16px;
    }
  }

  & ${DescriptionWrapper} {
    margin: 0;
    max-width: 588px;

    @media (max-width: ${size.lg}) {
      max-width: 600px;
    }

    @media (max-width: ${size.sm}) {
      max-width: 100%;
    }
  }

  & ${GridLegacyCards} {
    margin-bottom: 56px;

    @media (max-width: ${size.md}) {
      margin-bottom: 40px;
    }

    @media (max-width: ${size.xs}) {
      margin-bottom: 32px;
    }
  }
`
