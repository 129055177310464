import React from 'react'
import {
  Content,
  DescriptionWrapper,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './ChooseYourSpecialist.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const ChooseYourSpecialist = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Подберите максимально
                <br />
                совместимого с вами специалиста
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Каждая личность уникальна, и именно поэтому важно найти
                психолога, с которым у вас будет максимальная совместимость. Мы
                предоставляем возможность подобрать психолога, который будет
                лучше всего соответствовать вашим потребностям, стилю общения и
                жизненной философии.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ImageWrapper>
            <HeroImage alt="Подберите максимально совместимого с вами специалиста" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
