import React from 'react'
import {
  Content,
  DescriptionWrapper,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './YouTalkHelps.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const YouTalkHelps = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <ImageWrapper>
            <HeroImage alt="YouTalk поможет учесть индивидуальные особенности" />
          </ImageWrapper>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                YouTalk поможет учесть
                <br />
                индивидуальные особенности
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Наши онлайн-сессии с личным психологом в YouTalk – это удобный и
                конфиденциальный способ получить поддержку. Во время сессии вы
                сможете обсудить свои эмоции, переживания, проблемы и цели. Ваш
                психолог предоставит вам ценные инструменты для саморазвития,
                поможет рассмотреть ситуации с новой перспективой и разработает
                стратегии решения.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
